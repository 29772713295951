import React, { useEffect } from "react"
import PropTypes from "prop-types"
// import Helmet from "react-helmet"
import { gsap } from "gsap"
import moment from "moment"

import Layout from "../../layout"
import SEO from "../../seo"

import { Button } from "../../anti/buttons/buttons"
import { Card } from "../../anti/card/card"
// import { CardSlider } from "../../anti/card-slider/card-slider"
import { Container, Section } from "../../anti/grid/grid"
import { Cover } from "../../anti/cover/cover"

import NavbarBack from "../../global/navbar-back/navbar-back"

const showDateEvent = ({ eventStartDate, eventEndDate, eventStartTime }) => {
  const dateStart = moment(eventStartDate)
  const dateEnd = moment(eventEndDate)

  if (!!eventEndDate) {
    return `${dateStart.format("DD MMM")} - ${dateEnd.format(
      "DD MMM YYYY"
    )}, every ${eventStartTime}`
  } else {
    return `${dateStart.format("ddd, DD MMM YYYY")} ${eventStartTime &&
      "• " + eventStartTime}`
  }
}

const Event = ({
  status,
  countdown,
  coverImg,
  coverImgMd,
  artist,
  title,
  date,
  time,
  endDate,
  description,
  notes,
  btnText,
  btnLink,
  btnControl,
  videos,
  eventData,
}) => {
  const statusLabel = () => {
    if (status === "live") {
      return "Live"
    } else if (status === "upcoming") {
      return <span>{countdown}</span>
    } else {
      return "Event Has Ended"
    }
  }

  const { eventDatetime } = eventData

  useEffect(() => {
    // Cover content
    gsap.to(".cover-bg", {
      scale: 1,
      ease: "expo.out",
      duration: 3,
      delay: 0.25,
    })
    // Reveal Card
    gsap.to(".anim-img .card-bg > .el-after", {
      top: "0%",
      ease: "expo.out",
      duration: 0.5,
      delay: 0.3,
    })
    gsap.to(".anim-img .card-bg-img", {
      autoAlpha: "1",
      duration: 0,
      delay: 0.6,
    })
    gsap.to(".anim-img .card-body", {
      autoAlpha: "1",
      duration: 0,
      delay: 0.6,
    })
    gsap.to(".anim-img .card-bg > .el-before", {
      autoAlpha: "1",
      duration: 0,
      delay: 0.6,
    })
    gsap.to(".anim-img .card-bg > .el-after", {
      height: "0%",
      ease: "expo.out",
      duration: 0.5,
      delay: 0.8,
    })
  }, [])

  return (
    <Layout>
      <SEO title={artist} />
      {/* <Helmet>
        <body className={`bd-event ${status}`} />
      </Helmet> */}

      <NavbarBack link="/" text="Home" />

      <Cover
        variant="responsive"
        theme="dark"
        img={coverImg}
        imgMd={coverImgMd}
        imgHeight="h-250px h-md-600px"
        imgOverlay="30"
        className={`cover-event cover-full ${status}`}
      >
        <div className="overflow-hidden">
          <p className={`cover-label ${status}`}>{statusLabel()}</p>
        </div>
        <div className="overflow-hidden">
          <h1 className="cover-title">{artist}</h1>
        </div>
        {title && (
          <div className="overflow-hidden">
            <p className="cover-text title">{title}</p>
          </div>
        )}
        <div className="overflow-hidden">
          <p className="cover-text">{showDateEvent(eventDatetime)}</p>
        </div>
        {btnText && status !== "past" && (
          <div className="overflow-hidden">
            <Button variant="secondary" link={btnLink}>
              <span>{btnText}</span>
            </Button>
          </div>
        )}
        {btnText && status === "past" && btnControl === "visible" && (
          <div className="overflow-hidden">
            <Button variant="secondary" link={btnLink}>
              <span>{btnText}</span>
            </Button>
          </div>
        )}
        <div className="overflow-hidden">
          <p className="cover-text mb-0">{description}</p>
        </div>
      </Cover>

      {videos && (
        <Section className="sc-event-videos">
          <Container>
            <div className="row">
              {videos.map((video, i) => {
                return (
                  <div className="col-6 col-md-4">
                    <Card
                      variant="boxless"
                      title={video.title}
                      titleClassName="h6"
                      img={video.image.sourceUrl}
                      imgHeight="h-ratio-16-9"
                      link={video.youtubeLink}
                      className="card-event-video anim-img"
                      key={i}
                    />
                  </div>
                )
              })}
            </div>
          </Container>
        </Section>
      )}
    </Layout>
  )
}

Event.propTypes = {
  status: PropTypes.oneOf(["live", "upcoming", "past"]),
  className: PropTypes.string,
  children: PropTypes.any,
}

Event.defaultProps = {
  status: "past",
  className: "",
}

export default Event
