import React from "react"
import { Link } from "../../anti/utils/utils"

import logoNavbarDark from "../../../assets/img/brand/logo_ismaya-tv_dark.png"
import logoNavbarLight from "../../../assets/img/brand/logo_ismaya-tv_light.png"

const NavbarBack = ({ link, text }) => {
  return (
    <>
      <div className="navbar-wrapper navbar-back absolute-top">
        <nav className="navbar transparent dark">
          <div className="container position-relative">
            <Link className="navbar-brand" to={link}>
              <i className="far fa-arrow-left" />
              <img
                src={logoNavbarDark}
                className="img-fluid dark"
                alt="Logo"
                width="auto"
              />
              <img
                src={logoNavbarLight}
                className="img-fluid light"
                alt="Logo"
                width="auto"
              />
            </Link>
            {/* <Link className="btn btn-back" to={link}>
              <i className="far fa-arrow-left" /> &nbsp;{text}
            </Link> */}
          </div>
        </nav>
      </div>
    </>
  )
}

export default NavbarBack
