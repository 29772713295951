import React, { useReducer, useEffect } from "react"

import Event from "components/global/event/event"

// actions
import { ACTIONS } from "utils/reducer/actions"
import { eventsReducer } from "utils/reducer/reducer"

// moment
import moment from "moment"

const getDayRemaining = ({ eventStartDate }) => {
  const upcomingTime = moment(eventStartDate).valueOf()
  const now = moment().valueOf()

  const getDayRemaining = moment(eventStartDate).diff(moment(), "days")
  const isSingular = getDayRemaining === 0 ? "" : "s"

  if (now >= upcomingTime) {
    return (
      <>
        Live <strong>Today</strong>
      </>
    )
  } else {
    return (
      <>
        Live in <strong>{`${getDayRemaining + 1} Day${isSingular}`}</strong>
      </>
    )
  }
}

const EventDetails = ({ location, pageContext }) => {
  // console.log({ location, pageContext })
  const data = pageContext.data
  const [event, eventsDispatch] = useReducer(eventsReducer, data)

  const blocks = event.blocks.find(block => block.name === "acf/main-event")
  const eventData = blocks && blocks.MainEvent
  const { eventDatetime } = eventData

  // moment.tz.setDefault("Asia/Jakarta")

  useEffect(() => {
    eventsDispatch({ type: ACTIONS.UPDATE_EVENTS })
    const interval = setInterval(() => {
      eventsDispatch({ type: ACTIONS.UPDATE_EVENTS })
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  return (
    <>
      <Event
        status={event.status}
        countdown={getDayRemaining(eventDatetime)}
        coverImg={eventData.coverThumbnail.sourceUrl}
        coverImgMd={eventData.coverImage.sourceUrl}
        artist={eventData.title}
        title={eventData.text}
        date={eventDatetime.eventStartDate}
        time={eventDatetime.eventStartTime}
        endDate={eventDatetime.eventEndDate}
        description={eventData.description}
        eventData={eventData}
        btnText={eventData.button.buttonText}
        btnLink={eventData.button.buttonLink}
        btnControl={eventData.button.buttonControl}
        videos={eventData.videos}
      />
    </>
  )
}

export default EventDetails
